/* Web-Application View */

.about-container {
  background: #FBFFF5;
}

.about-section {
  display: flex;
  max-width: 1440px;
  margin: auto;
}

.about-block-one {
  flex: 1;
  margin: 5px;
  height: auto;
  padding-bottom: 87px;
}

.about-title {
  font-size: 28px;
  font-weight: 600;
  color: #121212;
  margin: 50px 0 0 70px;
}

.about-tagline {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(18, 18, 18, 0.7);
  width: auto;
  margin: 20px 25px 10px 70px;
}

.about-customer-title {
  color: #209541;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 40px 70px;
}

.about-why-invest-with-us-container {
  display: block;
}

.text-with-icon-contsiner {
  display: flex;
}
.about-why-us-card{
  display: flex;
  margin-left: 50px;
}

.about-block-two{
  flex: 0.4;
}

/* Mobile View Responsive  */
@media(max-width:440px) {
  .about-section {
    display: block;
  }

  .about-title {
    padding: 25px 0 0 20px;
    margin: auto;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }

  .about-tagline {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 20px 25px 10px 20px;
  }

  .about-customer-title {
    color: #209541;
    font-size: 18px;
    margin: 10px 0 40px 20px;
  }

  .text-with-icon-contsiner {
    display: flex;
    margin: 0 0 0 20px;
  }
  .about-why-us-card{
    display: flex;
    margin-left: 0;
  }
  .about-block-two{
    margin: 0;
   }
  
}