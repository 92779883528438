.page-section-header-container {
  padding-top: 86px;
  padding-bottom: 55px;
  text-align: center;
}
.page-section-header-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
  margin-bottom: 22px;
}

.page-section-header-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212325;
  opacity: 0.7;
}

@media (max-width:430px){
  .page-section-header-title{
    font-weight: 500;
font-size: 28px;
line-height: 147.7%;
text-align: center;
  }
  .page-section-header-subtitle {
    font-weight: 400;
font-size: 14px;
line-height: 16px;
text-align: center;
  }
}
@media (max-width:375px){
  .page-section-header-title{
    font-weight: 500;
font-size: 28px;
line-height: 147.7%;
text-align: center;
  }
  .page-section-header-subtitle {
    font-weight: 400;
font-size: 14px;
line-height: 16px;
text-align: center;
  }
}