/* Web-Application View */

.Why-invest-in-mysuru {
  background: #FBFFF5;
  width: auto;
}

.why-invest-in-mysure-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  padding-top: 49.9px;
  margin-bottom: 20px;
  color: #212325;
  padding-top: 86px;
}

.why-invest-in-mysure-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #212325;
  opacity: 0.7;
  padding-bottom: 55px;
}

.why-invest-cards-container {
  background-image: url("../../assets/images/palace1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.why-invest-in-mysure-person-block {
  flex: 0.7;
  flex-direction: column;
}

.why-invest-in-mysure-person-image {
  width: 100%;
  position: relative;
  height: 571px;
  padding-top: 183px;
}

.why-invest-points-container-large {
  flex: 1.1;
}

.water-connectivity-and-cleanest-city-container {
  display: flex;
  border-bottom: 1px dashed #209541;
}

.water {
  height: 44px;
}

.why-invest-icons {
  display: flex;
}

.why-invest-in-mysore-card-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  color: #000000;
  margin: auto 0;
}

.why-invest-in-mysore-card-sub-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 168.7%;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 20px;
  height: auto;
}

.cleanest {
  height: 175px;
  border-left: 1px dashed #209541;
  margin: 0 20px;
}

.water-connectivity-and-cleanest-city-container-end {
  display: flex;
}
.end-icons{
  margin: 20px 6px;
}

.why-invest-in-mysure-person-mobile-image {
  display: none;
}

/* Mobile-View Responsive */

@media (max-width:430px) {
  .why-invest-in-mysure-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 147.7%;
  }

  .why-invest-in-mysure-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  .why-invest-cards-container {
    background-image: none;
    display: block;
    margin: 0;
  }

  .why-invest-in-mysure-person-image {
    display: none;

  }

  .why-invest-points-container-large {
    display: block;
    margin: 0 10px;
  }
  
  .why-invest-in-mysore-card-sub-title {
    height: auto;
  }

  .water-connectivity-and-cleanest-city-container {
    display: block;
    border-bottom: 1px dashed #209541;
    width: auto;
  }

  .water {
    width: auto;
    height: 50px;
    padding: 10px 17px 120px 5px;
    border-bottom: 1px dashed #209541;
    margin: 0;
  }

  .cleanest {
    border-left: none;
    width: auto;
    height: 50px;
    padding: 10px 17px 120px 5px;
    border-bottom: 1px #209541;
    margin: 0;
  }

  .water-connectivity-and-cleanest-city-container-end {
    display: block;
    width: auto;
    border-bottom: 1px dashed #209541;

  }

  .why-invest-in-mysure-person-mobile-image {
    display: block;
  }

}

@media(max-width:1445px) {
  .why-invest-cards-container {
    margin: 0;
  }
}

@media (max-width:768px) {
  /* .why-invest-in-mysure-person-image{
    display: none;
  } */
  .why-invest-in-mysure-person-block{
    display: none;
  }
  .why-invest-in-mysure-person-mobile-image {
    display: block;
    width: 100%;
  }

}