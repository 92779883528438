 /* Web-Application View */

 .award {
   justify-content: center;
   background-color: #fbfff5;
   background-image: url("../../../public/assets/Awards/bagimage.png");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
 }

 .award-container {
   padding-bottom: 55px;
   padding-top: 60px;
   overflow: hidden;
 }

 .award-header {
   text-align: center;
   font-weight: 500;
   font-size: 40px;
   line-height: 47px;
   color: #212325;
   margin-bottom: 0px;
   padding: 60px 10px 0px 0px;
 }

 .award-sub-header {
   text-align: center;
   font-size: 18px;
   line-height: 47px;
   color: #828485;
   margin-bottom: 30px;
 }

 .award-logo-container {
   flex: 1;
   min-width: 200px;
 }

 .award-carousal-btn {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px;
 }

 .award-slider-action-holder {
   width: 5px;
 }

 .award-carousal-left-chevron-btn {
   position: relative;
   z-index: 999;
   border: 1px solid #999;
   border-radius: 100px;
   width: 55px;
   height: 55px;
   display: flex;
   align-items: center;
   justify-content: center;
   /* margin-top: 76px; */
   left: -80px;
   background: #ffffff57;
   cursor: pointer;
 }

 .award-carousal-right-chevron-btn {
   cursor: pointer;
   position: relative;
   z-index: 999;
   border: 1px solid #999;
   border-radius: 100px;
   width: 55px;
   height: 55px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: -55px;
   left: 30px;
   background: #ffffff;
 }

 .award-image-center-section {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
 }

 .award-receice-icon {
   justify-content: center;
   text-align: center;
   margin-top: 55px;
 }

 .award-receice-logo {
   justify-content: center;
   text-align: center;
   margin-top: -215px;
 }

 .award-receive-title {
   text-align: center;
   font-weight: 500;
   font-size: 27px;
   line-height: 21px;
   color: #212325;
   margin-top: 120px;
   margin-bottom: 20px;
 }

 .award-receive-subtitle {
   text-align: center;
   font-size: 16px;
   line-height: 22px;
   color: #828485;
   margin-bottom: 30px;
   width: 330px;
 }

 /* Mobile View Responsive */

 @media (max-width: 850px) {
   .award-header {
     font-size: 28px;
     margin-top: 30px;
   }

   .award-sub-header {
     font-size: 18px;
     line-height: 35px;
   }

   .award {
     background-image: none;
   }
 }

 @media (max-width: 430px) {
   .award-receive-title {
     font-weight: 500;
     font-size: 18.2878px;
     line-height: 14px;
   }

   .award-receive-subtitle {
     font-weight: 400;
     font-size: 10.8372px;
     line-height: 15px;
     text-align: center;
     width: 254px;
   }
 }