/* Web-apllicaton view */
.project-details-section {
    background: #FBFFF5;
    flex: 1;
}

.project-details-main-container {
    display: flex;
    flex: 1;
    min-height: 400px;
    max-width: 1440px;
    margin: auto;
}

.project-details-main-container2 {
    min-height: 400px;
    display: flex;
    /* flex: 1; */
    max-width: 1440px;
    margin: auto;
    margin-top: 0px;
    flex-direction: column;
}

.project-details-sector-container {
    min-height: 610px;
    margin: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
}

.project-details-sector-icon {
    display: flex;
}

.project-details-sector-share-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-right: 20px;
    width: 46px;
    height: 46px;
    box-shadow: 0px 1px 4px 3px rgba(42, 56, 0, 0.21);
}

.project-details-sector-like-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 46px;
    height: 46px;
    box-shadow: 0px 1px 4px 3px rgba(42, 56, 0, 0.21);
}

.project-details-sector-img-section {
    display: flex;
}

.project-details-left-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.project-details-banner-image-block {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 560px;
}

.project-details-banner-image {
    /* max-width: 700px;
        max-height: 600px; */
    width: 100%;
}

.project-details-right-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.project-deatals-approve-block {
    border-top: 0.3px solid rgba(32, 149, 65, 0.5);
    margin: -25px;
    padding: 30px;
    display: flex;
}

.project-details-sector-content {
    flex: 1;
}

.project-deatals-approve-block-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
    color: #212325;
}

.project-details-sector-location {
    display: flex;
    margin-top: 15px;
}

.project-deatals-approve-block-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #717171;
    margin-left: 15px;
}

.project-details-header-section {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
    justify-content: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin: 10px;
}

.project-details-header-option-txt {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: #212325;
    cursor: pointer;
}

.project-details-about-section {
    margin: 10px;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
    border: 1px solid #ccc;
}

.project-details-about-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    letter-spacing: 0.06em;
    color: #212325;
}

.project-details-about-section-content {
    padding-top: 28px;
}

.project-details-about-section-container {
    display: flex;
}

.project-details-about-section-icon {
    padding: 6px;
}

.project-details-about-section-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #212325;
    letter-spacing: 0.04em;
    padding-left: 10px;
    margin-bottom: 10px;
}

.project-details-units-section {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
}

.project-details-units-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.06em;
    color: #212325;
}

.project-details-units-section-content {
    padding-top: 22px;
}

.project-details-units-section-container {
    display: flex;
    flex-wrap: wrap;
}

.project-details-units-content {
    width: 260px;
    padding: 10px;
}

.project-details-units-section-content-block {
    display: flex;
}

.project-details-units-section-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-details-units-section-title {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04em;
    color: #A0A0A0;
    margin-bottom: 5px;
    padding-left: 10px;
}

.project-details-units-section-description {
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.04em;
    color: #212325;
    padding-left: 10px;
}

.project-details-amenities-section {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
}

.project-details-amenities-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.06em;
    color: #212325;
}

.project-details-amenities-section-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.project-details-amenities-section-cards {
    width: 115px;
    height: 118px;
    padding: 18px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 12.988px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin: 10px;
}

.project-details-amenities-section-cards-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.project-details-amenities-section-cards-title {
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.04em;
    color: #000000;
    width: 96px;
    margin: auto;
}

.project-details-locality-section {
    border: 1px solid #ccc;
    margin: 10px;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
}

.project-details-brocher-section {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
}

.project-details-locality-container {
    display: flex;
    flex: 1;
}

.project-details-locality-location-image {
    /* width: 36px;
        height: 36px;
        margin-top: 10px; */
}

.project-details-locality-title {
    display: flex;
    flex: 3;
    font-weight: 400;
    font-size: 16.7176px;
}

.project-details-locality-subtitle {
    display: flex;
    flex: 1;
    justify-content: space-around;
    font-weight: 500;
    font-size: 20.3873px;

}

.project-details-brocher-section {
    font-size: 20px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.06em;
    color: #212325;
    line-height: 25px;
}

.project-details-broucher-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-details-broucher-image {
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    margin-top: 0px;
}

.project-details-image-section {}

.project-details-broucher-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 14px 20px;
    gap: 11px;
    background: #209541;
    border-radius: 4px;
    width: 250px;
    height: 17px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    position: relative;
    top: 150px;
    margin-left: 250px;
}


.project-float {
    position: fixed;
    width: 60px;
    height: 200px;
    bottom: 400px;
    right: 220px;
    border-radius: 50px;
    text-align: center;
    z-index: 2000;
    top: 100px;
    padding: 30px;
}

.project-close-button {
    float: right;
    cursor: pointer;
    font-size: 20px;
    opacity: 0.8;
}

.project-car-image {
    height: 100%;
    padding-top: 20px;
}

.project-hide {
    display: none;
}

.project-details-button-approved {
    display: flex;
}

.project-details-contact-us-container {
    width: 417px;
    max-width: 417px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}


/* Mobile-View Responsive */

@media (max-width:430px) {
    .project-details-sector-container {
        min-height: auto;
    }

    .project-details-main-container {
        display: block;
    }

    .project-details-banner-image {
        width: 100%;
    }

    .project-details-banner-image-block {
        min-height: 300px;
    }

    .project-deatals-approve-block {
        display: block;
    }

    .project-details-broucher-image {
        margin: auto;
    }

    .project-details-image-section {
        width: 100%;
        height: 170px;
    }

    .project-details-header-section {
        padding: 20px 0px 0px 0px;
    }

    .project-details-header-option-txt {
        flex: auto;
    }

    .project-details-broucher-button {
        margin-left: -20px;
    }
    .project-details-main-container2{
        display:contents;
    }
}

@media (max-width:768px) {
    .project-details-main-container {
        display: unset;
    }
    .project-details-image-section {
        width: 100%;
    }
    }