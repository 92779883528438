.header-container {
  box-shadow: 0px -2px 2px rgba(62, 83, 3, 0.3),
    0px 1px 3px rgba(79, 107, 4, 0.2);
  position: relative;
}

.header-container-content {
  max-width: 1440px;
  margin: auto;
}

.header-primary-container {
  height: 97.33px;
  display: flex;
  padding-left: 70px;
}
.header-container-list{
  display: flex;
  align-items: center;
}
.header-container-list-txt{
  text-decoration: none;
}
.header-seccondary-container header-link{
  height: 56px;
  width: 22rem;
  position: relative;
  left: 50rem;
}
.header-secondary-container {
  display: flex;
  height: 56px;
}

.dropdown{
  float: left;
  color: black;
  text-decoration: none;
  font-size: 16px;
  align-items: center;
  margin: auto;

}

.dropdown1 {
  float: right;
  line-height: 16px;
  color: black;
  text-decoration: none;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding-top: 14px;
  padding-top: 11px;
  padding-left: 10px;

}

.dropdown2 {
  float: right;

}
.dropdown-logo {
  width: 62px;
  height: 37.6px;
}
.dropdown-logo2{
  width: 48px;
  height: 38.24px;
}

.header-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212325;
  padding: 0 27px;
  cursor: pointer;
}

.header-ham {
  display: none;
}
.header-ham1 {
  display: none;
}
.header-link-mobile {
  display: none;
}



@media (max-width: 899px) {
  .header-ham {
    display: block;
  }
  .header-ham1 {
    display: block;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
  
    border-top: 1px solid #fff;
    position: absolute;
   
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0;
    padding-bottom: 18px;
    width: 80%;
    background: #081b95;
  }
 
  .nav-links {
    padding: 10px;
    width: 100%;
    display: table;
  }
  .header-link-mobile {
    display: block;
   text-align: center;
    justify-content: center;
    padding: 6px;
    color: black;
    font-size: 10px;
    line-height: 10px;
  }

  .header-link {
    display: none;
  }

  .header-primary-container {
    padding-left: 20px;
    text-align: center;
    /* padding: 14px; */
    height: 84px;
    margin-right: 13px;
  }
}

@media (max-width:1024px){
  .header-seccondary-container{
    position: relative;
    left: 24rem !important;
  }

}


