.footer {
    background-color: #06340D;
}

.footer-container {
    background-color: #06340D;
}

.footer-logo-container {
    margin-left: 82px;
    margin-top: 70px;
}

.footer-content-container {
    display: flex;
}

.footer-social-link-icons {
    margin: 10px;
}

.footer-contentSection-1 {
    flex: 3;
}

.footer-contentSection-1>p {
    color: #E1E1E1;
    font-size: 14px;
    margin-left: 82px;
    line-height: 21px;
    margin-bottom: 20px;
    font-weight: 400;
}

.footer-contentSection-2 {
    flex: 2;
    margin-top: 71px;
    margin-left: 271px;
}

.footer-contentSection-3 {
    flex: 2;
    margin-top: 70px;
}

.footer-socialmedia-link-container-1 {
    display: flex;
    margin-left: 70px;
}

.footer-socialmedia-link-container-2 {
    display: none;
}

.footer-bottom {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #ffffff;
    opacity: 0.8;
    padding: 20px;
    background-color: #0F461E;
}

.footer-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 141.7%;
    color: #ffffff;
    margin: 5px;
    margin-bottom: 12px;
}


.footer-links,
.footer-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 141.7%;
    padding: 10px;
    letter-spacing: 0.3px;
    color: #ffffff;
    text-decoration: none;
}

.footer-links:hover {
    color: #c57c0e;
}

.footer-content:hover {
    color: #c57c0e;
}

.footer-golden-property-online {
    color: #ffffff;
    Font-style: Bold;
    Font-size: 16px;
    line-height: 141.7%;
    font-weight: 700;
    margin-top: 48px;
}

.footer-online-app-container {
    display: flex;

}

.footer-online-app {
    background-color: #FFFFFF;
    height: 45px;
    width: 145px;
    border-radius: 4px;
    margin-top: 30px;
    margin: 28px 17px 0px 1px;

}

.footer-online-app-icon {
    margin-top: 8px;
    margin-left: 10px;
}

.footer-online-app-text {
    color: #222222;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    margin: 10px;
}

.footer-contact-us-content-container {
    display: flex;
}

.footer-social-icons {
    height: 18px;
    margin-top: 12px;
}

.footer-devider {
    margin-top: 35px;
}

.footer-socialmedia-link-container-hide {
    display: none;
}


/* mobile view responsive */


@media (max-width: 430px) {
    .footer-header>img {
        display: none;
    }
}

@media (max-width: 780px) {
    .footer-socialmedia-link-container-1 {
        display: none;
    }

    .footer-socialmedia-link-container-hide {
        display: block;
        margin-left: 60px;
        margin-bottom: 20px;
    }

    .footer-content-container {
        display: flex;
        flex-direction: column;
    }

    .header-logo {
        width: 100px !important;
    }

    .footer-logo-container {
        margin-left: 18px;
        margin-top: 34px;
        margin-bottom: 10px;

    }

    .footer-contentSection-1>p {
        display: none;
    }

    .footer-contentSection-2 {
        margin-top: 0px;
        margin-left: 14px !important;
    }

    .footer-contentSection-3 {
        margin-left: 20px;
        margin-top: 20px;
    }

    .footer-golden-property-online {
        display: none;
    }

    .footer-online-app-container {
        display: none;
    }

    .footer-socialmedia-link-container-1 {
        margin-bottom: 28px;
    }

    .footer-bottom {
        font-size: 12px;
    }
}

@media (max-width: 1024px) {
    .footer-contentSection-2 {
        margin-left: 60px;
    }
}





