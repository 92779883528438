 /* Web-Application View */

.why-us-container {
  background: #FBFFF5;
  background-image: url("../../assets/images/Image.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  width: 100%;
  margin: 0,
}

.why-us-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  padding-top: 49.9px;
  margin-bottom: 20px;
  color: #212325;
  padding-top: 86px;
}

.why-us-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #212325;
  opacity: 0.7;
  padding-bottom: 55px;
}

.why-us-content-and-image-container{
  display: flex;
  flex: 2;
}

.why-us-content-section {
  margin-left: 14%;
}

.why-us-card1 {
  display: flex;
  margin-left: 70px;
}

.why-us-transparency-number {
  font-Weight: 700;
  font-Size: 60px;
  line-height: 75.7%;
  height: 45px;
  color: #FFAB26;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-right: 2px solid #FFAB26;
}

.why-us-transparency-block{
  padding: 16px; 
}

.why-us-transparency-title {
  font-weight: 500;
  font-size: 30px;
  color: #212325;
  margin-bottom: 10px;
}

.why-us-transparency-sub-title {
  margin-top: 10;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  max-width: 479px;
  color: rgba(33, 35, 37, 0.7);
}

.why-us-mobile-view-bottom-image {
  display: none;
}


/* Mobile View Responsive */

@media only screen and (max-width: 430px) {
  .why-us-mobile-view-bottom-image {
    margin-top: -90px;
    width: -webkit-fill-available;
    display: block;
  }
  .why-us-container {
    background-image: none;
  }
  .why-us-transparency-title {
    font-size: 22px;
    margin-bottom: 11px;
  }
  .why-us-transparency-sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-width: none;
  }
  .why-us-transparency-number {
    font-weight: 700;
    font-size: 42px;
    line-height: 75.7%;
  }
}

@media (max-width:1024px){
  .why-us-transparency-block {
    padding: 2px;
    margin-left: 9px;
}
  .why-us-transparency-sub-title {
    max-width: 340px !important;
  }
}
@media (max-width:1228px) {
  .why-us-transparency-sub-title {
    max-width: 440px;
  }
  .why-us-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #212325;
    opacity: 0.7;
  }
}
@media (max-width:1440px) {
  .why-us-content-section {
    margin-left: -30px !important;
  }
}

