/* Web-Application View */

.about-connect-info-container {
  position: fixed !important;
  z-index: 2000;
  top: 160px;
  margin: 7px;
  border-radius: 4px;
  filter: drop-shadow(0px 2px 20px rgba(67, 81, 1, 0.22));
}

.about-us-connect-info-body {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.about-us-connect-info-form-container {
  padding: 25px;
  flex: 1;
  background: #FFFFFF;
}

.about-us-connect-info-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #090909;
}

.about-us-connect-info {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.about-us-connect-info-info-container {
  display: flex;
  margin: 24px;
}

.about-us-connect-info-info-icon {
  margin-right: 24px;
}

.about-us-connect-info-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}

.about-us-connect-info-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.about-us-connect-info-form-input-container {
  padding: 10px 0;
}

.about-us-connect-info-form-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.about-us-connect-info-form-input {
  padding: 8px 12px;
  width: 95%;
  height: 10px;
  background: #ffffff;
  border: 1px solid rgba(9, 9, 9, 0.3);
  border-radius: 2px;
}

.about-us-connect-info-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex: 1;
  color: #FFFFFF;
  background: #209541;
  border-radius: 4px;
  border: none;
}

.about-us-connect-info-form-input input {
  border: none;
  outline: none;
  border-left: 1px solid #ccc;
  margin-left: 10px;
}

.about-us-float-car-image {
  position: fixed;
  width: 55px;
  height: 152px;
  bottom: 400px;
  right: 79px;
  top: 582px;
  cursor: pointer;
  text-align: center;
  z-index: 2000;
  display: none;
}

.about-us-close-car-image {
  position: fixed;
  width: 55px;
  height: 152px;
  bottom: 400px;
  right: 84px;
  top: 578px;
  cursor: pointer;
  text-align: center;
  z-index: 2000;
  display: none;
}

.about-us-connect-info-sub-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 17px;
  opacity: 0.8;
}

.about-us-close-button {
  float: right;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  display: none;
}

.about-us-mobile-close-image,
.about-us-mobile-fab-car-image {
  display: none;
}

.about-hide {
  display: none;
}

.about-us-connect-button-container {
  display: flex;
}

/* Mobie View Responsive */

@media (max-width:850px) {
  .about-us-connect-info-body {
    flex-direction: column;
  }

  .about-connect-info-container {
    left: 0;
    margin: 7px;
    position: fixed;
    right: 23px;
    top: 100px;
    z-index: 2000;
  }

  .about-us-mobile-close-image {
    display: block;
    position: fixed;
    /* top: 620px; */
    top:618px;
    left: 231px;
    cursor: pointer;
    width: 90px;
    z-index: 2000;
  }

  .about-us-mobile-fab-car-image {

    cursor: pointer;
    display: block;
    left: 230px;
    position: fixed;
    /* top: 560px; */
    top:560px;
    width: 90px;
    z-index: 2000;
  }

  .about-us-float-car-image,
  .about-us-close-car-image {
    display: none;
  }

  .overlay__background {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
  }

  .overlay__container {
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    padding: 30px;
    width: fit-content;
    height: fit-content;
  }

  .overlay__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .overlay__close:after {
    display: inline-block;
    content: "\00d7";
  }

  .overlay__close {
    border: none;
    background-color: transparent;
    font-size: 36px;
    cursor: pointer;
  }

  .about-us-close-button {
    float: right;
    cursor: pointer;
    font-size: 20px;
    opacity: 0.8;
    display: block;
  }
}