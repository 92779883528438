.project-cards {
  display: flex;
  margin: 30px;
}
.projects-filter-container{
  display: flex;
    justify-content: center;
    cursor: pointer;
    padding-bottom: 20px;

}
.project-card-residential-img-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.project-card-residential-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-card-residential-text{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 9px;
}
.project-card-farmland-img-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.project-card-farmland-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-card-farmland-text{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 9px;
}
.project-slider-action-holder{
  width: 5px;
}
.project-carousal-left-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  left: -20px;
  background: #ffffff57;
  cursor: pointer;
}
.project-cards-container{
  flex: 1;
  width: 50px;
}
.project-cards-section{
  display: flex;
  justify-content: center;
}
.project-cards-section-block{
  display: flex;
  flex-direction: column;
  width: 313px;
  height: 380px;
  border-radius: 25px;
  text-align: left;
}
.project-cards-section-image{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.project-card-section-image{
  width: 313px;
  height: 217px;
  border-radius: 25px;
  background: #D9D9D9 ;
}
.project-card-section-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-top: 14px;
  padding-left: 20px;
  color: #000000;
}
.project-card-sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  padding-left: 20px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
}
.project-card-primary-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 179px;
  height: 45px;
  border-radius: 4px;
  background: #209541 ;
  border: 0.817073px solid #209541 ;
  margin-left: 34px;
}
.project-card-primary-btn-txt{
  font-size: 14px;
  font-weight: 500;
  line-height: 9px;
  color: #fff;
  padding: 25px;
}

.project-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  left: -30px;
  background: #ffffff;
}
.project-slider-small-action-holder {
  display: none;
}

.mobile-project-filter-selection{
  display: none;
}
.mobile-project-filter-selection-option{
padding: 25px;
font-weight: 500;
font-size: 14px;
color: #209541 ;
text-transform: capitalize;
border-bottom: 3px solid #209541 ;
}
.mobile-project-filter-selection-option-2{
  text-align: center;
  padding: 20px;
  margin-top: -45px;
  background: #fff;
  margin-bottom: 10px;
}



/* Mobile View Responsive */

@media (max-width: 1060px) {
  .project-slider-action-holder {
    display: none;
  }
  .project-slider-small-action-holder {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 650px) {
  .project-cards {
    margin: 0;
  }
  .projects-filter-container {
   display: none;
  }
  .project-filter-farmland {
    min-width: 296px;
  }
  .mobile-project-filter-selection{
    display: block;
    text-align: center;
      display: flex;
      justify-content: center;
      box-shadow: rgb(0 0 0 / 13%) 0px 1px 0px;
      margin-bottom: 50px;
  }
}

/* ///mobile-project-filter-selection add proper media query breakpoint */
