.treanding-project-filter-container {
  display: flex;
  justify-content: center;
}
.trending-project-card-main-container {
  display: flex;
  margin: auto;
}
.trending-project-card-container {
  flex: 1;
  width: 50px;
}
.trending-project-cards {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
.trending-project-cards-contents {
  overflow: hidden;
  width: 400px;
  height: 465px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  background: #fff;
  text-align: left;
  padding-bottom: 5px;
  box-shadow: 0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #D0DAF4;
}
.trending-project-image-section {
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 350px;
}
.trending-project-image {
  width: 400px;
  height: 100%;
  border: #D9D9D9;
  border-radius: 24px 24px 0px 0px;
}
.trending-project-content {
  display: flex;
  /* height: 100px; */
}
.trending-project-title-content {
  padding-top: 10px;
  padding-bottom: 15px;
}
.trending-project-card-title {
  /* Farm Land Daksha Chandana */
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 14px;
  padding-left: 20px;
  /* or 117% */
  /* primary */
  color: #212325;
}
.trending-project-card-sub-title {
  padding-left: 20px;
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.4);
}
.trending-project-card-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding: 10px;
}


/* Mobile View Responsive */

@media (max-width: 320px) {
  .trending-project-cards-contents {
    width: 311px !important;
    margin-left: 1px;
  }

  .trending-project-image {
    width: 312px !important;
  }
}

@media (max-width: 768px) {
  .trending-project-cards-contents {
    width: 370px;
  }

  .trending-project-image {
    width: 370px;
  }
}

/* @media (max-width:1440px){
    .immmg{
      width: 100%;
    }
  } */