.project-connect-info-container {
  /* position: fixed; */
  z-index: 2000;
  top: 100px;
  margin: 7px;
  right: 23px;
}

.project-connect-info-body {

  border: 1px solid #ccc;
  border-radius: 5px;
}


.project-connect-info-form-container {
  padding: 25px;
  flex: 1;
  background: #FFFFFF;
}

.project-connect-info-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  margin-bottom: 20px;
  color: #090909;
}

.project-connect-info {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  margin-bottom: 20px;
  color: #FFFFFF;
}

.project-connect-info-info-container {
  display: flex;
  margin: 24px;
}

.project-connect-info-info-icon {
  margin-right: 24px;
}

.project-connect-info-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Sec */

  color: #FFFFFF;
}

.project-connect-info-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  /* or 23px */

  letter-spacing: 0.05em;

  /* Sec */

  color: #FFFFFF;
}

.project-connect-info-form-input-container {
  padding: 10px 0;
}

.project-connect-info-form-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Character / Title .85 */

  color: rgba(0, 0, 0, 0.85);
  /* margin-bottom: 5px; */

}

.project-connect-info-form-input {
  padding: 8px 12px;

  width: 95%;
  height: 10px;

  /* Neutral/1 */

  background: #ffffff;
  border: 1px solid rgba(9, 9, 9, 0.3);
  border-radius: 2px;
}

.project-connect-info-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    padding: 10px;
 
 
  flex: 1;

  color: #FFFFFF;
  /* Primary */

  background: #209541;
  border-radius: 4px;
  border: none;
}

.project-connect-info-form-input input {
  border: none;
  outline: none;
  border-left: 1px solid #ccc;
  margin-left: 10px;
}

.project-float-car-image {
  position: fixed;
  width: 55px;
  height: 152px;
  bottom: 400px;
  right: 79px;
  top: 582px;
  cursor: pointer;
  text-align: center;
  z-index: 2000;
 display: none;
}
.project-close-car-image{
  position: fixed;
  width: 55px;
  height: 152px;
  bottom: 400px;
  right: 84px;
  top: 578px;
  cursor: pointer;
  text-align: center;
  z-index: 2000;
  display: none;
}


.project-connect-info-sub-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #FFFFFF;
  margin-bottom: 17px;
  opacity: 0.8;
}

.project-close-button {
  float: right;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  display: none;
}
.project-mobile-close-image ,.project-mobile-fab-car-image{
  display: none;
}
.hide{
  display: none;
}
.project-details-connectus-green-tab{
  padding: 10px;
}


/* Mobile view responsive */

@media (max-width:850px){
  .project-connect-info-body {
    flex-direction: column;
  }
  .project-connect-info-container {
    left: 0;
  }
  .project-connect-info-container {
    position: fixed;
    z-index: 2000;
    top: 100px;
    margin: 7px;
    right: 23px;
  }
  .project-mobile-close-image,.project-mobile-fab-car-image{
    display: block;
    position: fixed;
    top: 560px;
    left:230px;
    cursor: pointer;
    width: 90px;
    z-index: 2000;
  }
  .project-float-car-image ,.project-close-car-image{
    display: none;
  }
  .overlay__background {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
  }
  
  .overlay__container {
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    padding: 30px;
    width: fit-content;
    height: fit-content;
  }
  
  .overlay__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .overlay__close:after {
    display: inline-block;
    content: "\00d7";
  }
  
  .overlay__close {
    border: none;
    background-color: transparent;
    font-size: 36px;
    cursor: pointer;
  }
  .project-close-button {
    display: block;
  }
  .project-details-connectus-green-tab{
    display: none;
  }
}


  