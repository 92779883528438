/* 
.amenities-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  left: -30px;
  background: #ffffff;
}
.amenities-slider-small-action-holder {
  display: none;
}
.amenities-card-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;


  letter-spacing: 0.05em;
  margin-bottom: 6px;
  color: #000000;
}
.amenities-card-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;


  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
} */



.other-projects-filter-container{
  display: flex;
  justify-content: center;
}
.other-projects-container{
  display: flex;
  margin: 30px;
}
.other-projects-slider-action-holder{
  width: 5px;
}
.other-projects-carousal-left-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  left: -20px;
  background: #ffffff57;
  cursor: pointer;
}
.other-projects-section-container{
  flex: 1;
  width: 50px;
}
.other-projects-cards-container{
  display: flex;
  justify-content: center;
}
.other-projects-cards{
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 313px;
  height: 380px;
  border-radius: 25px;
}
.other-projects-cards-img-section{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.other-projects-cards-img{
  max-width: 313px;
  max-height: 217px;
}
.other-project-cards-title{
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  margin-bottom: 6px;
  margin-top: 14px;
  padding-left: 20px;
  color: #000000;
}
.other-project-cards-sub-title{
  font-weight: 400;
  font-size: 14px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
}
.other-projects-card-primary-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 179px;
  height: 45px;
  background: #209541;
  border: 0.817073px solid #209541;
  border-radius: 4px;
  margin-left: 20px;
  box-sizing: border-box;
}
.other-projects-card-primary-btn-txt{
  font-size: 14px;
  font-weight: 500;
  line-height: 9px;
  color: #fff;
  padding: 25px;
}
.other-projects-carousal-right-chevron-btn{
  cursor: pointer;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  left: -30px;
  background: #ffffff;
}

@media (max-width: 320px) {
.other-project-cards-title {
  margin-left: 7px;
}
.other-project-cards-sub-title{
  margin-left: 7px;
}
.other-projects-card-primary-btn{
  margin-left: 30px;
}
.other-projects-cards-img-section{
  margin-left: 40px;
}
}

@media (max-width: 1060px) {
  .other-projects-slider-action-holder{
    display: none;
  }
  /* .amenities-slider-small-action-holder {
    display: flex;
    justify-content: center;
  } */
}
@media (max-width: 650px) {
  .other-projects-filter-container {
    flex-direction: column;
    align-items: center;
  }
  .project-filter-farmland {
    min-width: 296px;
  }
}
