

.more-insights-card-section {
  display: flex;
  margin: 20px;
}

.more-insights-slider-action-holder {
  width: 5px;
}

.more-insights-cards {
  flex: 1;
  width: 50px;
}

.more-insights-card-container {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

.more-insights-cards-block-section {
  width: 381px;
  height: 249.69px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #D0DAF4;

}

.more-insights-cards-title {
  font-weight: 500;
  font-size: 20.8041px;
  line-height: 24px;
  padding: 30px 0px 12px 21px;
  color: #000000;
}

.more-insights-cards-description {
  font-weight: 400;
  line-height: 168.7%;
  letter-spacing: 0.05em;
  padding: 20px;
  color: rgba(0, 0, 0, 0.6);
  height: 80px;
  overflow: hidden;
}

.more-insights-cards-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;

}

.more-insights-cards-arrow-mark {
  height: 10px;
  margin-right: 10px;
}

.more-insights-carousal-left-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 99px;
  left: -20px;
  background: #ffffff57;
  cursor: pointer;
}

.more-insights-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 99px;
  left: -35px;
  background: #ffffff;
}

.more-insights-slider-small-action-holder {
  display: none;
}

.more-carousal-right-chevron-btn {
  cursor: pointer;
  margin-top: 0;
  left: 0;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

/* mobile view responsive */

@media (max-width: 1060px) {
  .more-insights-slider-action-holder {
    display: none;
  }

  .more-insights-slider-small-action-holder {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1215px) {
  .more-insights-card-container {
    transform: scale(0.9);
  }
}