.blog-section-container {
    background: #FBFFF5;
    padding: 50px;

}

.blog-container {
    display: flex;
    flex-direction: row;
}

.blog-section-image-container {
    flex: 1;
    border: 1.84722px solid #EAEAEF;
    border-radius: 8px 11px 4px 4px;
    margin-bottom: 20px;
    margin-left: 223px;
}

.blog-image {
    margin-bottom: 20px;
}

.blog-section-pop-container {
    flex: 1;
}

.blog-section-tag-line {
    margin-left: 20px;
    font-weight: 500;
    font-size: 26px;
    line-height: 27px;
    color: #121212;
    margin-bottom: 24px;
}

.blog-section-vector-icon {
    display: flex;
    margin-left: 23px;
    margin-bottom: 32px;
}

.blog-section-vector-icon1 {
    flex: 1;
    display: flex;
}

.blog-section-vector-icon-number {
    margin-left: 4px;
    font-weight: 400;
    font-size: 13.3726px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 4px;
}

.blog-section-vector-icon1 button {
    border: none !important;
    background: none;
    cursor: pointer;
}

.blog-section-vector-icon2 {
    flex: 3;
    text-align: right;
    margin-right: 28px;
}

.blog-section-vector-icon button {
    border: none !important;
    background: none;
    cursor: pointer;
}

.blog-section-paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 23px;
    color: rgba(18, 18, 18, 0.7);
    margin-bottom: 20px;
    border-top: 1px solid #E8E8E8;
}

.blog-section-sub-paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 23px;
    color: rgba(18, 18, 18, 0.7);
    margin-bottom: 10px;
}

.blog-section-read-more-text {

    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #209541;
    margin-bottom: 25px;
    cursor: pointer;
}



/* mobile view responsive  */


@media (max-width:430px) {
    .blog-section-container {
        padding: 10px;

    }

    .blog-container {
        display: block;
    }

    .blog-section-image-container {
        margin: 0;
    }

    .blog-image-1 {
        width: -webkit-fill-available;
    }

    .blog-section-vector-icon2 {
        flex: inherit;
    }

    .blog-section-paragraph {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .blog-section-sub-paragraph {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .blog-section-tag-line {
        font-size: 18px;
        font-weight: 500;
    }

}


@media (max-width:1228px) {
    .blog-section-image-container {
       flex:1.5;
       
    }

    .blog-image-1 {
        width: 100%;
    }
}

@media (max-width:1440px) {
   
    .blog-section-image-container {
        margin: 10px;
    }
}