.our-customer-speak-container {
  display: flex;
  margin: 0px;
}

.our-customer-speak-container-block {
  flex: 1;
  width: 50px;
  cursor: pointer;
}

.our-customer-speaks {
  display: flex;
  justify-content: center;
}

.our-customer-speak-card {
  display: flex;
  height: 318px;
  /* max-height: 318px; */
  width: 676px;
  border-radius: 25px;
  border: 1px solid #EAEAEF;
  text-align: left;
  background: #fff;
}

.our-customer-speak-card-image-block {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;

}

.our-customer-speak-image {
  width: 285px;
  height: 272px;
}

.our-customer-speak-content-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.our-customer-speak-quota-block {
  display: flex;
  opacity: 0.4;
  padding-right: 5px;
  max-width: 333px;
  overflow: hidden;

}

.our-customer-speak-quota {
  flex: 1;
}

.our-customer-speak-tag-line {
  max-width: 333px;
  height: 72px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(55, 55, 55, 0.7);
  margin-top: 10px;
}

.our-customer-speak-sub-tag-line {
  width: 353px;
  height: 72px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: #209541;
}

/* mobile view responsive  */

@media (max-width:780px) {
  .our-customer-speak-card {
    display: block;
    height: auto;
  }

  .our-customer-speak-image {
    width: 600px;
    margin-top: 10px;
  }

  .our-customer-speak-quota-block {
    max-width: none;
    margin-top: 15px;
    padding: 0px 20px 10px 20px;

  }

  .our-customer-speak-tag-line {
    max-width: 500px;
    margin-left: 84px;
  }

  .our-customer-speak-sub-tag-line {
    width: auto;
  }

}

@media (max-width:430px) {
  .our-customer-speak-card {
    width: -webkit-fill-available;
    margin: 10px;
  }

  .our-customer-speak-image {
    width: 379px;
  }

  .our-customer-speak-tag-line {
    margin-left: 4px;
  }
}