.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 2px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-item:hover {
  cursor: pointer;
}
.pagination-item.selected {
  border: 1px solid #209541;
}
.arrow::before {
  /* position: relative; */
  content: '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  /* border-right: 0.12em solid rgba(0, 0, 0, 0.87); */
  /* border-top: 0.12em solid rgba(0, 0, 0, 0.87); */
}
.arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.arrow.right {
  transform: rotate(45deg);
}
.arrow.disabled {
  pointer-events: none;
}
  .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }
  .arrow:hover {
    background-color: transparent;
    cursor: default;
  }

  .pagination-display {
    margin-left: 650px;
}


@media (max-width:430px) {
  .pagination-display {
    margin-left: auto;
}
}
@media (max-width:1228px) {
  .pagination-display {
    margin-left: auto;
}
}
/* @media (max-width:430px) {
  .pagination-display {
    margin-left: auto !important;
  }
}
@media (max-width:1440px) {
  .pagination-display {
    margin-left: 611px;
  }
} */