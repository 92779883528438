.up-coming-project-section {
  display: flex;
}
.up-coming-project-container {
  flex:1;
  width:50px;
}


.up-coming-project-image {
  cursor: pointer;
  width: 100%;
}

@media (max-width:430px) {
  .up-coming-project-image {
    height: 350px;
    
  }
}